<template>
    <label :class="{ 'checkbox' : true, 'expand' : expand, 'show-invalid' : !checkbox }">
        <input type="checkbox" v-model="checkbox" :required="required">
        <i>
            <svg viewbox="0 0 12 10" fill="currentColor">
                <polyline points="1.5 6 4.5 9 10.5 1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polyline>
            </svg>
        </i>
        <span><slot/></span>
    </label>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        expand: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    computed: {
        checkbox: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
};
</script>
