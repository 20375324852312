<template>
    <Main :show-title="false">
        <Empty illustration="contracts">
            <template #title>Opret din gratis<br>Selvbygger+ konto</template>
            <template #description>Kom igang for 0,- pr. md. helt uden binding</template>
        </Empty>
        Allerede bruger?&nbsp;<router-link to="/login">Log ind</router-link>
    </Main>
    <aside>
        <h1>{{ title }}</h1>
        <Form @submit="register" :processing="processing" :message="message">
            <fieldset>
                <Input type="text" placeholder="Fornavn*" v-model="fields.firstname" class="half" :required="true" />
                <Input type="text" placeholder="Efternavn*" v-model="fields.lastname" class="half" :required="true" />
                <Input type="email" placeholder="E-mail*" v-model="fields.email" :required="true" autocomplete="username email" ref="email" />
                <Input type="password" placeholder="Adgangskode*" v-model="fields.password" :required="true" autocomplete="new-password" ref="password" />
                <Input type="password" placeholder="Bekræft adgangskode*" v-model="fields.confirmPassword" :required="true" autocomplete="new-password"
                       @focusout="checkConfirmPassword" @focus="invalidPasswordMessage = null" :data-invalid="invalidPasswordMessage" :show-invalid-message="!!invalidPasswordMessage" />
                <Select placeholder="Henvisning" :required="true" v-model="fields.referral">
                    <option>Sparekassen Danmark</option>
                    <option>Sparekassen Thy</option>
                    <option>Sociale Medier</option>
                    <option>Venner/familie</option>
                    <option>Andet</option>
                </Select>
                <Checkbox :required="true" v-model="fields.privacy">Jeg accepterer <a href="https://selvbyggerplus.dk/betingelser/" target="_blank">betingelser</a> og <a href="https://selvbyggerplus.dk/privatlivspolitik/" target="_blank">privatlivspolitikken</a></Checkbox>
                <Checkbox v-model="fields.mails">Få vores nyhedsbreve med gode tips til dit byggeprojekt – det udkommer en gang i måneden</Checkbox>
                <ActionButton label="Opret bruger"/>
            </fieldset>
        </Form>
    </aside>
</template>

<script>
import Main from '@/components/Main.vue';
import Checkbox from '@/components/inputs/Checkbox.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { auth, httpsRequest, usersCollection } from '@/utils/firebase';
import useState from '@/store';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import Empty from '@/components/Empty.vue';
import { useRoute } from 'vue-router';
import Select from '@/components/inputs/Select.vue';

export default {
    name: 'Register',
    components: {
        Select,
        Main,
        Checkbox,
        ActionButton,
        Form,
        Input,
        Empty,
    },
    setup() {
        const { initState } = useState();
        const { title } = useRoute().meta;
        return { initState, title };
    },
    data() {
        return {
            fields: {
                firstname: null,
                lastname: null,
                email: null,
                password: null,
                confirmPassword: null,
                privacy: false,
                mails: false,
                referral: '',
            },
            invalidPasswordMessage: null,
            processing: false,
            message: null,
        };
    },
    methods: {
        checkConfirmPassword() {
            this.invalidPasswordMessage = (this.fields.password !== this.fields.confirmPassword) ? 'Adgangskode stemmer ikke overens' : null;
        },
        async register() {
            if (this.fields.password !== this.fields.confirmPassword) {
                this.checkConfirmPassword();
            } else {
                this.processing = true;

                try {
                    const { firstname, lastname, email, password, mails, referral } = this.fields;
                    const userData = { firstname, lastname, email, mails, referral };
                    const createAuthResponse = await auth.createUserWithEmailAndPassword(email, password);
                    await usersCollection.doc(createAuthResponse.user.uid).set(userData);

                    const uuid = createAuthResponse.user.uid;
                    const request = await httpsRequest.httpsCallable('createUpodiCustomerAndSubscription');
                    await request({ uuid, firstname, lastname, email });

                    await this.initState(createAuthResponse.user.uid);
                    // TODO: Opret brugeren i MailChimp
                    await this.$router.push({ name: 'create-project' });
                } catch (err) {
                    this.processing = false;

                    if (err.code === 'auth/weak-password') {
                        this.$refs.password.$el.setAttribute('data-invalid', 'Kodeord skal bestå af minimum 6 karakterer');
                        this.$refs.password.$el.classList.add('show-invalid');
                    } else if (err.code === 'auth/email-already-in-use') {
                        this.$refs.email.$el.setAttribute('data-invalid', 'E-mailen er allerede i brug af en anden konto');
                        this.$refs.email.$el.classList.add('show-invalid');
                    } else {
                        this.message = { text: err.message, error: true };
                    }
                }
            }
        },
    },
};
</script>
